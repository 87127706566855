import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Page404Component } from './pages/page404/page404.component';

export const filePath: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.component').then((m) => m.HomeComponent),
    data: {
      sitemap: {
        loc: '/',
      },
      meta: {
        loc: '/',
        fileName: 'HOME',
        title: 'Axon Trade - OEMS for Digital Asset Trading',
        description:
          'We provide a unique combination of low-latency execution services, real-time market data feeds, and a secure hosted environment inside Equinix data centers.',
        keywords:
          'crypto oems, crypto fix api, fix api, crypto exchange fix api, crypto trading, algotrading, crypto hft, high frequency trading crypto',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
        og_type: 'website',
      },
    },
  },
  {
    path: 'blog',
    loadComponent: () => import('./pages/blog/blog.component').then((m) => m.BlogComponent),
    data: {
      sitemap: {
        loc: 'blog',
      },
      meta: {
        loc: 'blog',
        fileName: '/blog',
        title: 'Blog | Axon Trade',
        description: 'New and updates on Axon Trade products and services',
        keywords:
          'fix api,market data,real time market data,fix api crypto,binance fix api,fix api for crypto trading,fix api for digital asset trading,industry,fix protocol,fix protocol for crypto,crypto exchange status,crypto latency arb,crypto latency arbitrage,latency arb trading,low-latency,mt4 crypto,mt5 crypto,metaquotes crypto,mt4 bridge for crypto,kucoin fix api,kucoin trading api,kucoin hft,bitget fix api,bitget trading api,bitget algo trading',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'products/fix-api',
    loadComponent: () => import('./pages/products/fix-api/fix-api.component').then((m) => m.FixApiComponent),
    data: {
      sitemap: {
        loc: 'products/fix-api',
      },
      meta: {
        loc: 'products/fix-api',
        fileName: 'products/fix-api',
        title: 'FIX API for digital asset trading | Axon Trade',
        description:
          'FIX API for digital asset trading, real-time normalized market data feeds, low-latency execution services, and a secure environment inside Equinix data centers.',
        keywords: 'fix api,crypto trading,cryptocurrency,digital assets,digital asset trading,crypto exchanges',
        imgUrl: '/assets/twitter-card/axon-trade-fix-api-twitter-card.jpg',
        og_type: 'article',
        canonical: 'products/fix-api',
      },
    },
  },
  {
    path: 'fix-api-overview',
    loadComponent: () =>
      import('./pages/products/fix-api-overview/fix-api-overview.component').then((m) => m.FixApiOverviewComponent),
    data: {
      sitemap: {
        loc: 'fix-api-overview',
      },
      meta: {
        loc: 'fix-api-overview',
        fileName: '/fix-api-overview',
        title: 'FIX API Overview | Axon Trade',
        description:
          'FIX API is a proven, decades old method of connecting markets. We have created our own that connects all of the major cryptocurrency exchanges. FIX is widely adopted & much faster',
        keywords: 'fix,financial information exchange,digital assets,crypto,cryptocurrency',
        imgUrl: '/assets/twitter-card/axon-trade-fix-api-overview-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'products/real-time-normalized-market-data',
    loadComponent: () =>
      import('./pages/products/market-data/market-data.component').then((m) => m.MarketDataComponent),
    data: {
      sitemap: {
        loc: 'products/real-time-normalized-market-data',
      },
      meta: {
        loc: 'products/real-time-normalized-market-data',
        fileName: '/products/real-time-normalized-market-data',
        title: 'Real-time Normalized Crypto Market Data | Axon Trade',
        description:
          'Axon Trade provides real-time crypto market data through FIX and WebSockets API for all popular exchanges, including Binance, Coinbase, OKX, Kraken, and Huobi',
        keywords:
          'crypto market data,crypto real time market data,crypto market data api,crypto market data provider,binance market data,coinbase market data,kraken market data,okx market data',
        imgUrl: '/assets/twitter-card/axon-trade-market-data-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'supported-exchanges',
    loadComponent: () =>
      import('./pages/supported-exchanges/supported-exchanges.component').then((m) => m.SupportedExchangesComponent),
    data: {
      sitemap: {
        loc: 'supported-exchanges',
      },
      meta: {
        loc: 'supported-exchanges',
        fileName: '/supported-exchanges',
        title: 'Supported Exchanges | Axon Trade',
        description:
          'Axon Trade is connected to 40+ crypto exchanges. We support all markets, available on exchanges - spot, margin, perpetual wasp, futures, and options',
        keywords:
          'crypto,crypto exchanges,crypto trading,binance,huobi,okx,bybit,bitmex,bitfinex,coinbase,hft,high frequency trading,algotrading',
        imgUrl: '/assets/twitter-card/axon-trade-supported-exchanges-page-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'ads/binance-fix-api',
    loadComponent: () =>
      import('./pages/ads/binance-fix-api/binance-fix-api.component').then((m) => m.BinanceFixApiComponent),
    data: {
      sitemap: {
        loc: 'ads/binance-fix-api',
      },
      meta: {
        loc: 'ads/binance-fix-api',
        fileName: '/ads/binance-fix-api',
        title: 'Binance FIX API | Axon Trade',
        description:
          'FIX API for Binance and Binance US crypto exchange. Use Axon Trade OEMS to trade on Binance and Binance US and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'binance fix api,binance us fix api,binance professional api,binance trading,binance oems,binance hft,binance high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-binance-fix-api-page-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bitmart-fix-api',
    loadComponent: () =>
      import('./pages/ads/bitmart-fix-api/bitmart-fix-api.component').then((m) => m.BitMartFixApiComponent),
    data: {
      sitemap: {
        loc: 'bitmart-fix-api',
      },
      meta: {
        loc: 'bitmart-fix-api',
        fileName: '/bitmart-fix-api',
        title: 'BitMart FIX API | Axon Trade',
        description:
          'FIX API for BitMart crypto exchange. Use Axon Trade OEMS to trade on BitMart and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'bitmart fix api,bitmart professional api,bitmart trading,bitmart oems,bitmart hft,bitmart high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-bitmart-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'contact-us',
    loadComponent: () => import('./pages/contact-us/contact-us.component').then((m) => m.ContactUsComponent),
    data: {
      sitemap: {
        loc: 'contact-us',
      },
      meta: {
        loc: 'contact-us',
        fileName: '/contact-us',
        title: 'Axon Trade - OEMS for Digital Asset Trading',
        description:
          'We provide a unique combination of low-latency execution services, real-time market data feeds, and a secure hosted environment inside Equinix data centers.',
        keywords:
          'crypto oems, crypto fix api, fix api, crypto exchange fix api, crypto trading, algotrading, crypto hft, high frequency trading crypto',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
        og_type: 'website',
      },
    },
  },
  {
    path: 'access-demo',
    loadComponent: () =>
      import('./pages/access-demo-environment/access-demo-environment.component').then(
        (m) => m.AccessDemoEnvironmentComponent
      ),
    data: {
      sitemap: {
        loc: 'access-demo',
      },
      meta: {
        loc: 'access-demo',
        fileName: '/access-demo',
        title: 'Axon Trade - OEMS for Digital Asset Trading',
        description:
          'We provide a unique combination of low-latency execution services, real-time market data feeds, and a secure hosted environment inside Equinix data centers.',
        keywords:
          'crypto oems, crypto fix api, fix api, crypto exchange fix api, crypto trading, algotrading, crypto hft, high frequency trading crypto',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
        og_type: 'website',
      },
    },
  },
  {
    path: 'gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer',
    loadComponent: () =>
      import(
        './pages/blog/pages/gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer/gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer.component'
      ).then((m) => m.GateIoAndAxonTradeStrategicCollaborationAndExclusiveOfferComponent),
    data: {
      sitemap: {
        loc: 'gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer',
      },
      meta: {
        loc: 'gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer',
        fileName: '/gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer',
        title: 'Gate.io and Axon Trade\u0027s Strategic Collaboration. and Exclusive Offer',
        description:
          'Gate.io partners with Axon Trade to enhance trading for professionals and institutions, offering FIX API, low-latency execution, and a 3-month zero-fee promotion with a free VPS',
        keywords:
          'Gate.io, cryptocurrency exchange, crypto trading, EMS, FIX API, real-time market data, order management, futures trading, margin trading, digital assets, VPS, HFT',
        imgUrl: '/assets/blog-card-images/gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bitmart-partners-with-axon-trade',
    loadComponent: () =>
      import('./pages/blog/pages/bitmart-partners-with-axon-trade/bitmart-partners-with-axon-trade.component').then(
        (m) => m.BitmartPartnersWithAxonTradeComponent
      ),
    data: {
      sitemap: {
        loc: 'bitmart-partners-with-axon-trade',
      },
      meta: {
        loc: 'bitmart-partners-with-axon-trade',
        fileName: '/bitmart-partners-with-axon-trade',
        title: 'Axon Trade Partners with BitMart. Exclusive Offer and Advanced Trading Solutions',
        description:
          'Axon Trade partners with BitMart to offer free trading for three months, reduced fees, and a free VPS. Discover advanced trading tools, including EMS integration with real-time data and order management.',
        keywords:
          'BitMart, cryptocurrency exchange, crypto trading, EMS, FIX API, real-time market data, order management, staking, futures trading, margin trading, digital assets, VPS, HFT',
        imgUrl: '/assets/blog-card-images/bitmart-partners-with-axon-trade.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'optimization-techniques-for-fix-based-trading-applications',
    loadComponent: () =>
      import(
        './pages/blog/pages/optimization-techniques-for-fix-based-trading-applications/optimization-techniques-for-fix-based-trading-applications.component'
      ).then((m) => m.OptimizationTechniquesForFixBasedTradingApplicationsComponent),
    data: {
      sitemap: {
        loc: 'optimization-techniques-for-fix-based-trading-applications',
      },
      meta: {
        loc: 'optimization-techniques-for-fix-based-trading-applications',
        fileName: '/optimization-techniques-for-fix-based-trading-applications',
        title: 'Optimization techniques for FIX-based trading applications | Axon Trade',
        description: 'The article discusses optimization techniques for FIX-based trading applications',
        keywords:
          'fix api,market data,real time market data,fix api crypto,binance fix api,fix api for crypto trading,fix api for digital asset trading,industry',
        imgUrl: '/assets/blog-card-images/axon-tarde-optimization-techniques-for-FIX-based-trading-applications.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'how-to-build-your-next-trading-system-using-axon-trade-oems',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-to-build-your-next-trading-system-using-axon-trade-oems/how-to-build-your-next-trading-system-using-axon-trade-oems.component'
      ).then((m) => m.HowToBuildYourNextTradingSystemUsingAxonTradeOemsComponent),
    data: {
      sitemap: {
        loc: 'how-to-build-your-next-trading-system-using-axon-trade-oems',
      },
      meta: {
        loc: 'how-to-build-your-next-trading-system-using-axon-trade-oems',
        fileName: '/how-to-build-your-next-trading-system-using-axon-trade-oems',
        title: 'How to build your next trading system using Axon Trade OEMS | Axon Trade',
        description:
          'We are pleased to invite you to our upcoming webinar How to build your next trading system using Axon Trade OEMS. We will guide you through the extensive functionality of our FIX API for trading and market data, as well as provide an in-depth explanation on how to seamlessly integrate with our Management REST API. We look forward to sharing our knowledge and insights with you.',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-tarde-how-to-build-your-next-trading-system-using-Axon-Trade-OEMS.png',
      },
    },
  },
  {
    path: 'axon-trade-oems-is-now-available-in-equinix-hk2',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-oems-is-now-available-in-equinix-hk2/axon-trade-oems-is-now-available-in-equinix-hk2.component'
      ).then((m) => m.AxonTradeOemsIsNowAvailableInEquinixHk2Component),
    data: {
      sitemap: {
        loc: 'axon-trade-oems-is-now-available-in-equinix-hk2',
      },
      meta: {
        loc: 'axon-trade-oems-is-now-available-in-equinix-hk2',
        fileName: '/axon-trade-oems-is-now-available-in-equinix-hk2',
        title: 'Axon Trade OEMS is Now Available in Equinix HK2 | | Axon Trade',
        description:
          'We are happy to announce that Axon Trade\u0027s OEMS for digital asset trading is now available in the Equinix HK2 location – Hong Kong! We have connectors with 40+ exchanges, including these ones\u2236 BinanceBybitOKXHuobi Global',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-OEMS-New-Point-of-Presence-equinix-hk2.png',
      },
    },
  },
  {
    path: 'api-updates-january-2023',
    loadComponent: () =>
      import('./pages/blog/pages/api-updates-january-2023/api-updates-january-2023.component').then(
        (m) => m.ApiUpdatesJanuary2023Component
      ),
    data: {
      sitemap: {
        loc: 'api-updates-january-2023',
      },
      meta: {
        loc: 'api-updates-january-2023',
        fileName: '/api-updates-january-2023',
        title: 'XTRD | FIX API | Updates | Axon Trade',
        description:
          'January 2023 FIX API updates - universal Order Cancel/Replace Request and ability to fetch exchange statuses using TradingSessionStatusRequest message',
        keywords:
          'crypto oems, crypto fix api, fix api, crypto exchange fix api, crypto trading, algotrading, crypto hft, high frequency trading crypto',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-API-updates-january-2023.png',
      },
    },
  },
  {
    path: 'bjf-trading-group-partners-with-axon-trade',
    loadComponent: () =>
      import(
        './pages/blog/pages/bjf-trading-group-partners-with-axon-trade/bjf-trading-group-partners-with-axon-trade.component'
      ).then((m) => m.BjfTradingGroupPartnersWithAxonTradeComponent),
    data: {
      sitemap: {
        loc: 'bjf-trading-group-partners-with-axon-trade',
      },
      meta: {
        loc: 'bjf-trading-group-partners-with-axon-trade',
        fileName: '/bjf-trading-group-partners-with-axon-trade',
        title: 'BJF Trading Group Partners With Axon Trade | | Axon Trade',
        description:
          'FX Software development company BJF Trading Group, announces today its partnership with Axon Trade, OEMS for digital asset trading. This partnership will allow customers to use sophisticated trading algorithms created by BJF Trading Group, across the entire spectrum of cryptocurrency exchanges supported by the Axon Trade OEMS. &quot;Digital asset space is still full of different inefficiencies that might be exploited to gain a certain profit. Of course, there is no free lunch, and to get positive results, you must be properly equipped and educated. BJF Trading Group did tremendous work discovering small operational nuances of traditional and crypto markets and turning them into algos.Axon Trade provides you with the tools that you can use to &quot;test the digital water&quot;, said Serg Gulko, Co-Founder and CEO at Axon Trade. Founded in 2000, BJF Trading group is a boutique Forex and Cryptocurrencies software development company that provides solutions for FX trading, specializing in Expert',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-bjf-trading-group.png',
      },
    },
  },
  {
    path: 'lessons-after-ftx-collapse',
    loadComponent: () =>
      import('./pages/blog/pages/lessons-after-ftx-collapse/lessons-after-ftx-collapse.component').then(
        (m) => m.LessonsAfterFtxCollapseComponent
      ),
    data: {
      sitemap: {
        loc: 'lessons-after-ftx-collapse',
      },
      meta: {
        loc: 'lessons-after-ftx-collapse',
        fileName: '/lessons-after-ftx-collapse',
        title: 'Lessons after FTX collapse | | Axon Trade',
        description:
          'By Axon Trade\u0027s CEO Serg Gulko With FTX collapse, you might notice a growing number of voices that DEFI is a solution. It is not. You\u0027ll replace one risk with another. A balanced approach - that\u0027s the answer. Never put all eggs into a single basket.Rule of thumb on reducing risks with crypto - check for interconnections. By trading on FTX using the money you borrow on BlockFi you expose yourself to potential chain effects.If you don\u0027t have the time/money/willpower to do proper due diligence, one of the solutions is to use brokers (I never thought I\u0027d say something like that...) instead of direct market access. But if you do have time, resources, and willpower to invest in robust trading infrastructure that helps you to diversify and manage risks - consider using Axon Trade OEMS. Do not lock yourself with other FTXs.',
        keywords: 'industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/lessons-after-FTX-collapce.png',
      },
    },
  },
  {
    path: 'latency-arbitrage-on-crypto-markets-using-axon-trade-oems',
    loadComponent: () =>
      import(
        './pages/blog/pages/latency-arbitrage-on-crypto-markets-using-axon-trade-oems/latency-arbitrage-on-crypto-markets-using-axon-trade-oems.component'
      ).then((m) => m.LatencyArbitrageOnCryptoMarketsUsingAxonTradeOemsComponent),
    data: {
      sitemap: {
        loc: 'latency-arbitrage-on-crypto-markets-using-axon-trade-oems',
      },
      meta: {
        loc: 'latency-arbitrage-on-crypto-markets-using-axon-trade-oems',
        fileName: '/latency-arbitrage-on-crypto-markets-using-axon-trade-oems',
        title: 'Latency arbitrage on crypto markets using Axon Trade OEMS | Axon Trade',
        description:
          'The article describes one of Axon Trade\u0027s side projects - a latency arbitrage trading system.',
        keywords: 'crypto latency arb,crypto latency arbitrage,latency arb trading,industry,low-latency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/latency-arbitrage-on-crypto-markets-using-axon-trade-oems.png',
      },
    },
  },
  {
    path: 'axon-trade-to-integrate-with-brokeree-solutions',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-to-integrate-with-brokeree-solutions/axon-trade-to-integrate-with-brokeree-solutions.component'
      ).then((m) => m.AxonTradeToIntegrateWithBrokereeSolutionsComponent),
    data: {
      sitemap: {
        loc: 'axon-trade-to-integrate-with-brokeree-solutions',
      },
      meta: {
        loc: 'axon-trade-to-integrate-with-brokeree-solutions',
        fileName: '/axon-trade-to-integrate-with-brokeree-solutions',
        title: 'MT4/MT4 Crypto bridge by Brokeree/XTRD | Axon Trade',
        description:
          'Brokeree Solutions, a turnkey solutions developer for multi-asset brokers, has just announced the integration of its flagship Liquidity Bridge with Axon Trade – orders and execution management system (OEMS) for digital asset trading with a strong focus on low-latency and high-throughput execution. Multi-server Liquidity Bridge is a comprehensive solution that combines advanced A/B book order execution, smart liquidity aggregation, and risk management. With this integration, brokers may introduce new crypto instruments for their clients on MetaTrader 4 and 5 platforms via the market data and liquidity provided by Axon Trade. &quot;We provide access to major US, Asia, and Europe exchanges. By leveraging our stack, clients can easily increase their liquidity offering by adding new LPs from the management panel without spending weeks for integration followed by neverending maintenance,&quot; said Serg Gulko, CEO of Axon Trade. &quot;I came to the digital asset space from FX and am familiar with the',
        keywords: 'mt4 crypto,mt5 crypto,metaquotes crypto,mt4 bridge for crypto',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-brokeree.png',
      },
    },
  },
  {
    path: 'kucoin-is-now-available-through-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/kucoin-is-now-available-through-axon-trade-fix-api/kucoin-is-now-available-through-axon-trade-fix-api.component'
      ).then((m) => m.KucoinIsNowAvailableThroughAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'kucoin-is-now-available-through-axon-trade-fix-api',
      },
      meta: {
        loc: 'kucoin-is-now-available-through-axon-trade-fix-api',
        fileName: '/kucoin-is-now-available-through-axon-trade-fix-api',
        title: 'KuCoin is now available through Axon Trade | Axon Trade',
        description:
          'Axon Trade OEMS for digital assets trading allows receiving normalized market data and actively trading on KuCoin\u0027s markets using unified FIX 4.4 end-point. ',
        keywords: 'kucoin fix api,kucoin trading api,kucoin hft',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-kucoin.png',
      },
    },
  },
  {
    path: 'bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors',
    loadComponent: () =>
      import(
        './pages/blog/pages/bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors/bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors.component'
      ).then((m) => m.BitgetPartnersWithAxonTradeToOfferSeamlessTradingCorridorForInstitutionalInvestorsComponent),
    data: {
      sitemap: {
        loc: 'bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors',
      },
      meta: {
        loc: 'bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors',
        fileName: '/bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors',
        title: 'Bitget partners with Axon Trade | Axon Trade',
        description: 'Bitget partners with Axon Trade to offer seamless trading corridor for institutional investors',
        keywords: 'bitget fix api,bitget trading api,bitget algo trading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-bitget.png',
      },
    },
  },
  {
    path: 'whitebit-is-now-available-through-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/whitebit-is-now-available-through-axon-trade-fix-api/whitebit-is-now-available-through-axon-trade-fix-api.component'
      ).then((m) => m.WhitebitIsNowAvailableThroughAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'whitebit-is-now-available-through-axon-trade-fix-api',
      },
      meta: {
        loc: 'whitebit-is-now-available-through-axon-trade-fix-api',
        fileName: '/whitebit-is-now-available-through-axon-trade-fix-api',
        title: 'WhiteBIT is now available through Axon Trade FIX API | Axon Trade',
        description:
          'Axon Trade OEMS for digital assets trading allows receiving normalized market data and actively trading on WhiteBit\u0027s markets using unified FIX 4.4 end-point.',
        keywords: 'fix api,whitebit,crypto,cryptocurrency,crypto trading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-whitebit.png',
      },
    },
  },
  {
    path: 'coinflex-is-now-available-through-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/coinflex-is-now-available-through-axon-trade-fix-api/coinflex-is-now-available-through-axon-trade-fix-api.component'
      ).then((m) => m.CoinflexIsNowAvailableThroughAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'coinflex-is-now-available-through-axon-trade-fix-api',
      },
      meta: {
        loc: 'coinflex-is-now-available-through-axon-trade-fix-api',
        fileName: '/coinflex-is-now-available-through-axon-trade-fix-api',
        title: 'CoinFLEX is now available through Axon Trade FIX API | Axon Trade',
        description:
          'We are happy to announce that from now CoinFLEX is available through our OEMS. Both spot and derivatives markets can be accessed using the same FIX 4.4. API',
        keywords: 'fix api,crypto,cryptocurrency,derivatives,trading,crypto trading,coinflex',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-coinflex.png',
      },
    },
  },
  {
    path: 'axon-trade-opens-up-in-ty8-data-center',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-opens-up-in-ty8-data-center/axon-trade-opens-up-in-ty8-data-center.component'
      ).then((m) => m.AxonTradeOpensUpInTy8DataCenterComponent),
    data: {
      sitemap: {
        loc: 'axon-trade-opens-up-in-ty8-data-center',
      },
      meta: {
        loc: 'axon-trade-opens-up-in-ty8-data-center',
        fileName: '/axon-trade-opens-up-in-ty8-data-center',
        title: 'Axon Trade Opens Up in TY8 Data Center | Axon Trade',
        description:
          'Axon Trade\u0027s OEMS for digital asset trading is now available in the Equinix TY8 location - Tokyo, Japan',
        keywords: 'oems for crypto,oems,crypto trading,ty8,ty8 data center',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-ty8-data-center.png',
      },
    },
  },
  {
    path: 'axon-trade-oems-for-crypto',
    loadComponent: () =>
      import('./pages/blog/pages/axon-trade-oems-for-crypto/axon-trade-oems-for-crypto.component').then(
        (m) => m.AxonTradeOemsForCryptoComponent
      ),
    data: {
      sitemap: {
        loc: 'axon-trade-oems-for-crypto',
      },
      meta: {
        loc: 'axon-trade-oems-for-crypto',
        fileName: '/axon-trade-oems-for-crypto',
        title: 'Axon Trade OEMS for Crypto | Axon Trade',
        description:
          'This article addresses the most common questions we received about Axon Trade OEMS for digital assets trading.',
        keywords: 'crypto,cryptocurrency,digital assets,crypto trading,oems,hft,high frequency trading,algotrading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-oems-for-crypto.png',
      },
    },
  },
  {
    path: 'renko-based-scalper-for-binance-using-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/renko-based-scalper-for-binance-using-axon-trade-fix-api/renko-based-scalper-for-binance-using-axon-trade-fix-api.component'
      ).then((m) => m.RenkoBasedScalperForBinanceUsingAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'renko-based-scalper-for-binance-using-axon-trade-fix-api',
      },
      meta: {
        loc: 'renko-based-scalper-for-binance-using-axon-trade-fix-api',
        fileName: '/renko-based-scalper-for-binance-using-axon-trade-fix-api',
        title: 'Scalper for Binance Using Axon Trade FIX API | Axon Trade',
        description: 'This article is an instruction on how to build a scalping application that trades on Binance.',
        keywords: 'binance,crypto trading,renko,fix api,crypto,cryptocurrency,algotrading,algo trading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-binance-fix-api.png',
      },
    },
  },
  {
    path: 'how-to-use-axon-trade-fix-api-from-javascript-node-js',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-to-use-axon-trade-fix-api-from-javascript-node-js/how-to-use-axon-trade-fix-api-from-javascript-node-js.component'
      ).then((m) => m.HowToUseAxonTradeFixApiFromJavascriptNodeJsComponent),
    data: {
      sitemap: {
        loc: 'how-to-use-axon-trade-fix-api-from-javascript-node-js',
      },
      meta: {
        loc: 'how-to-use-axon-trade-fix-api-from-javascript-node-js',
        fileName: '/how-to-use-axon-trade-fix-api-from-javascript-node-js',
        title: 'How to use Axon Trade FIX API from JavaScript/node.js | Axon Trade',
        description: 'A small demo on how to use Axon Trade FIX API from JavaScript/node.js.',
        keywords: 'fix api,javascript,crypto trading,algo trading,algotrading,crypto,cryptocurrency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/how-to-use-xtrd-fix-api-from-javascript.png',
      },
    },
  },
  {
    path: 'bybit-is-now-available-through-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/bybit-is-now-available-through-axon-trade-fix-api/bybit-is-now-available-through-axon-trade-fix-api.component'
      ).then((m) => m.BybitIsNowAvailableThroughAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'bybit-is-now-available-through-axon-trade-fix-api',
      },
      meta: {
        loc: 'bybit-is-now-available-through-axon-trade-fix-api',
        fileName: '/bybit-is-now-available-through-axon-trade-fix-api',
        title: 'Bybit is now available through Axon Trade FIX API | Axon Trade',
        description: 'Bybit is available through Axon Trade FIX API.',
        keywords: 'crypto,crypto trading,bybit,fix api',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-bybit.png',
      },
    },
  },
  {
    path: 'changes-in-naming-convention-for-perpetual-swaps-futures-and-options',
    loadComponent: () =>
      import(
        './pages/blog/pages/changes-in-naming-convention-for-perpetual-swaps-futures-and-options/changes-in-naming-convention-for-perpetual-swaps-futures-and-options.component'
      ).then((m) => m.ChangesInNamingConventionForPerpetualSwapsFuturesAndOptionsComponent),
    data: {
      sitemap: {
        loc: 'changes-in-naming-convention-for-perpetual-swaps-futures-and-options',
      },
      meta: {
        loc: 'changes-in-naming-convention-for-perpetual-swaps-futures-and-options',
        fileName: '/changes-in-naming-convention-for-perpetual-swaps-futures-and-options',
        title: 'Changes in Naming For Futures, and Options | Axon Trade',
        description: 'We changed the naming convention for perpetual swaps, futures, and options.',
        keywords: 'perpetual swaps,futures,options,crypto,crypto trading',
        og_type: 'article',
        imgUrl:
          '/assets/blog-card-images/axon-trade-changes-in-naming-convention-for-perpetual-swaps-futures-and-options.png',
      },
    },
  },
  {
    path: 'how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving/how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving.component'
      ).then((m) => m.HowToImproveExecutionInCryptoMarketsOrAxonTradeArtOfShavingComponent),
    data: {
      sitemap: {
        loc: 'how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving',
      },
      meta: {
        loc: 'how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving',
        fileName: '/how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving',
        title: 'How to Improve Execution in Crypto Markets | Axon Trade',
        description:
          'An article by Axon Trade\u0027s Co-Founder and CTO Serg Gulko on improving execution in crypto markets.',
        keywords: 'high-frequency trading,crypto trading,crypto,cryptocurrency,low-latency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-how-to-improve-execution-in-crypto-markets.png',
      },
    },
  },
  {
    path: 'axon-trade-opens-up-in-ld4-data-center',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-opens-up-in-ld4-data-center/axon-trade-opens-up-in-ld4-data-center.component'
      ).then((m) => m.AxonTradeOpensUpInLd4DataCenterComponent),
    data: {
      sitemap: {
        loc: 'axon-trade-opens-up-in-ld4-data-center',
      },
      meta: {
        loc: 'axon-trade-opens-up-in-ld4-data-center',
        fileName: '/axon-trade-opens-up-in-ld4-data-center',
        title: 'Axon Trade Opens Up in LD4 Data Center | Axon Trade',
        description:
          'We are happy to announce that Axon Trade\u0027s OEMS for digital asset trading is now available in the Equinix LD4 location - London, UK!',
        keywords: 'oems,digital assets,crypto trading,data center,crypto',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-opens-up-in-ld-4-data-center.png',
      },
    },
  },
  {
    path: 'top-5-quant-trading-and-algotrading-blogs',
    loadComponent: () =>
      import(
        './pages/blog/pages/top-5-quant-trading-and-algotrading-blogs/top-5-quant-trading-and-algotrading-blogs.component'
      ).then((m) => m.Top5QuantTradingAndAlgotradingBlogsComponent),
    data: {
      sitemap: {
        loc: 'top-5-quant-trading-and-algotrading-blogs',
      },
      meta: {
        loc: 'top-5-quant-trading-and-algotrading-blogs',
        fileName: '/top-5-quant-trading-and-algotrading-blogs',
        title: 'Top 5 Quant Trading and Algotrading Blogs | Axon Trade',
        description:
          'Top 5 Quant Trading and Algotrading Blogs\u2236 QuantInsti, Hackernoon, Quantpedia, Quantifiable Edges, Alvarez Quant Trading.',
        keywords: 'algo trading, algotrading, quant trading, crypto, crypto trading, industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/top-quant-trading-and-algotrading-blogs.png',
      },
    },
  },
  {
    path: 'coinbase-pro-api-review',
    loadComponent: () =>
      import('./pages/blog/pages/coinbase-pro-api-review/coinbase-pro-api-review.component').then(
        (m) => m.CoinbaseProApiReviewComponent
      ),
    data: {
      sitemap: {
        loc: 'coinbase-pro-api-review',
      },
      meta: {
        loc: 'coinbase-pro-api-review',
        fileName: '/coinbase-pro-api-review',
        title: 'Coinbase Pro API Review | Axon Trade',
        description: 'Coinbase Pro API Review by Axon Trade\u0027s Co-Founder and CTO Serg Gulko',
        keywords: 'coinbase,api,crypto,crypto trading,crypto exchange',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/coinbase-pro-api-overview.png',
      },
    },
  },
  {
    path: 'different-deployment-options',
    loadComponent: () =>
      import('./pages/blog/pages/different-deployment-options/different-deployment-options.component').then(
        (m) => m.DifferentDeploymentOptionsComponent
      ),
    data: {
      sitemap: {
        loc: 'different-deployment-options',
      },
      meta: {
        loc: 'different-deployment-options',
        fileName: '/different-deployment-options',
        title: 'Different deployment options | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236 Different deployment options',
        keywords: 'crypto,crypto trading,digital assets,colocation,cross-connection,vpn,vps',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-different-deployment-options.png',
      },
    },
  },
  {
    path: 'engineering-axon-trade-fort-knox-of-authentication',
    loadComponent: () =>
      import(
        './pages/blog/pages/engineering-axon-trade-fort-knox-of-authentication/engineering-axon-trade-fort-knox-of-authentication.component'
      ).then((m) => m.EngineeringAxonTradeFortKnoxOfAuthenticationComponent),
    data: {
      sitemap: {
        loc: 'engineering-axon-trade-fort-knox-of-authentication',
      },
      meta: {
        loc: 'engineering-axon-trade-fort-knox-of-authentication',
        fileName: '/engineering-axon-trade-fort-knox-of-authentication',
        title: 'Axon Trade\u0027s Fort Knox of Authentication | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236 Engineering Axon Trade\u0027s Fort Knox of Authentication',
        keywords: 'security,crypto,crypto trading,digital assets',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-fort-knox-of-authentification.png',
      },
    },
  },
  {
    path: 'normalized-api-for-trading',
    loadComponent: () =>
      import('./pages/blog/pages/normalized-api-for-trading/normalized-api-for-trading.component').then(
        (m) => m.NormalizedApiForTradingComponent
      ),
    data: {
      sitemap: {
        loc: 'normalized-api-for-trading',
      },
      meta: {
        loc: 'normalized-api-for-trading',
        fileName: '/normalized-api-for-trading',
        title: 'Normalized API for trading | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236 Normalized API for trading',
        keywords: 'api,crypto,crypto trading,crypto exchanges,digital assets',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-normalized-api-for-trading.png',
      },
    },
  },
  {
    path: 'normalized-symbology',
    loadComponent: () =>
      import('./pages/blog/pages/normalized-symbology/normalized-symbology.component').then(
        (m) => m.NormalizedSymbologyComponent
      ),
    data: {
      sitemap: {
        loc: 'normalized-symbology',
      },
      meta: {
        loc: 'normalized-symbology',
        fileName: '/normalized-symbology',
        title: 'Normalized symbology | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236  Normalized symbology',
        keywords: 'market data,crypto,cryptocurrency,crypto trading,digital assets',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-normalized-symbology.png',
      },
    },
  },
  {
    path: 'reliable-market-data',
    loadComponent: () =>
      import('./pages/blog/pages/reliable-market-data/reliable-market-data.component').then(
        (m) => m.ReliableMarketDataComponent
      ),
    data: {
      sitemap: {
        loc: 'reliable-market-data',
      },
      meta: {
        loc: 'reliable-market-data',
        fileName: '/reliable-market-data',
        title: 'Reliable Market Data | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236  Reliable Market Data',
        keywords: 'market data,crypto,crypto trading,digital assets,cryptocurrency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-reliable-market-data.png',
      },
    },
  },
  {
    path: 'how-to-trade-on-huobi-using-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-to-trade-on-huobi-using-axon-trade-fix-api/how-to-trade-on-huobi-using-axon-trade-fix-api.component'
      ).then((m) => m.HowToTradeOnHuobiUsingAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'how-to-trade-on-huobi-using-axon-trade-fix-api',
      },
      meta: {
        loc: 'how-to-trade-on-huobi-using-axon-trade-fix-api',
        fileName: '/how-to-trade-on-huobi-using-axon-trade-fix-api',
        title: 'How to trade on Huobi using Axon Trade FIX API | Axon Trade',
        description: 'How to trade on Huobi using Axon Trade FIX API',
        keywords: 'huobi,crypto,cryptocurrency,crypto trading,digital assets',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-huobi-fix-api.png',
      },
    },
  },
  {
    path: 'recap-after-europe-trading-show-2019',
    loadComponent: () =>
      import(
        './pages/blog/pages/recap-after-europe-trading-show-2019/recap-after-europe-trading-show-2019.component'
      ).then((m) => m.RecapAfterEuropeTradingShow2019Component),
    data: {
      sitemap: {
        loc: 'recap-after-europe-trading-show-2019',
      },
      meta: {
        loc: 'recap-after-europe-trading-show-2019',
        fileName: '/recap-after-europe-trading-show-2019',
        title: 'Recap after Europe Trading Show 2019 | Axon Trade',
        description: 'For Axon Trade, the Trading Show has become a must-attend event. This year we visited London!',
        keywords: 'trading show,crypto trading,crypto exchanges',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-recap-after-europe-trading-show-2019.png',
      },
    },
  },
  {
    path: 'the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019',
    loadComponent: () =>
      import(
        './pages/blog/pages/the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019/the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019.component'
      ).then((m) => m.TheAxonTradeTeamWillTakePartInTheTradingShowEurope2019Component),
    data: {
      sitemap: {
        loc: 'the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019',
      },
      meta: {
        loc: 'the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019',
        fileName: '/the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019',
        title: 'The Axon Trade team will take part in Trading Show Europe | Axon Trade',
        description:
          'The Axon Trade team will take part in The Trading Show in London on October 17, 2019 as an exhibitor and sponsor.',
        keywords: 'trading show,crypto trading,exchanges,crypto exchanges',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-trading-show-2020.png',
      },
    },
  },
  {
    path: '5-reasons-why-we-need-more-interconnected-crypto-exchanges',
    loadComponent: () =>
      import(
        './pages/blog/pages/five-reasons-why-we-need-more-interconnected-crypto-exchanges/five-reasons-why-we-need-more-interconnected-crypto-exchanges.component'
      ).then((m) => m.FiveReasonsWhyWeNeedMoreInterconnectedCryptoExchangesComponent),
    data: {
      sitemap: {
        loc: '5-reasons-why-we-need-more-interconnected-crypto-exchanges',
      },
      meta: {
        loc: '5-reasons-why-we-need-more-interconnected-crypto-exchanges',
        fileName: '/5-reasons-why-we-need-more-interconnected-crypto-exchanges',
        title: 'Why We Need More Interconnected Crypto Exchanges | Axon Trade',
        description: '5 Reasons Why We Need More Interconnected Crypto Exchanges',
        keywords: 'crypto exchanges,cryptocurrency,institutional traders,liquidity,industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-why-we-need-more-interconnected-crypto-exchanges.png',
      },
    },
  },
  {
    path: '6-factors-feared-by-institutional-investors-in-cryptocurrency',
    loadComponent: () =>
      import(
        './pages/blog/pages/six-factors-feared-by-institutional-investors-in-cryptocurrency/six-factors-feared-by-institutional-investors-in-cryptocurrency.component'
      ).then((m) => m.SixFactorsFearedByInstitutionalInvestorsInCryptocurrencyComponent),
    data: {
      sitemap: {
        loc: '6-factors-feared-by-institutional-investors-in-cryptocurrency',
      },
      meta: {
        loc: '6-factors-feared-by-institutional-investors-in-cryptocurrency',
        fileName: '/6-factors-feared-by-institutional-investors-in-cryptocurrency',
        title: '6 Factors Feared by Institutional Investors in Crypto | Axon Trade',
        description: 'Six factors affecting the appetite of institutional investors in cryptocurrency.',
        keywords: 'institutional crypto traders,institutional investors,crypto traders,banks,industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/6-factors-feared-by-institutional-investors-in-cryptocurrency.png',
      },
    },
  },
  {
    path: 'axon-trade-team-will-take-part-in-the-trading-show-new-york-2019',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-team-will-take-part-in-the-trading-show-new-york-2019/axon-trade-team-will-take-part-in-the-trading-show-new-york-2019.component'
      ).then((m) => m.AxonTradeTeamWillTakePartInTheTradingShowNewYork2019Component),
    data: {
      sitemap: {
        loc: 'axon-trade-team-will-take-part-in-the-trading-show-new-york-2019',
      },
      meta: {
        loc: 'axon-trade-team-will-take-part-in-the-trading-show-new-york-2019',
        fileName: '/axon-trade-team-will-take-part-in-the-trading-show-new-york-2019',
        title: 'The Axon Trade team will take part in Trading Show New York | Axon Trade',
        description:
          'The Axon Trade team will take part in The Trading Show in New York on September 25, 2019 as an exhibitor and sponsor.',
        keywords: 'trading show,crypto trading,crypto exchanges,exchange technology',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-trading-show-2019.png',
      },
    },
  },
  {
    path: 'how-institutional-traders-would-benefit-from-global-crypto-exchanges',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-institutional-traders-would-benefit-from-global-crypto-exchanges/how-institutional-traders-would-benefit-from-global-crypto-exchanges.component'
      ).then((m) => m.HowInstitutionalTradersWouldBenefitFromGlobalCryptoExchangesComponent),
    data: {
      sitemap: {
        loc: 'how-institutional-traders-would-benefit-from-global-crypto-exchanges',
      },
      meta: {
        loc: 'how-institutional-traders-would-benefit-from-global-crypto-exchanges',
        fileName: '/how-institutional-traders-would-benefit-from-global-crypto-exchanges',
        title: 'Institutional Traders and Global Crypto Exchanges | Axon Trade',
        description:
          'Axon Trade is building new financial products to improve access to global crypto exchanges for institutional investors',
        keywords: 'exchange,cryptocurrency,crypto,traders,institutional traders,industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/how-institutional-traders-would-benefit-from-global-crypto-exchanges.png',
      },
    },
  },
  {
    path: 'axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services/axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services.component'
      ).then(
        (m) => m.AxonTradeAndCexIoAnnounceIntegrationPartnershipOfferingInstitutionalGradeTradingServicesComponent
      ),
    data: {
      sitemap: {
        loc: 'axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services',
      },
      meta: {
        loc: 'axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services',
        fileName:
          '/axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services',
        title: 'XTRD and CEX.IO Institutional-Grade Trading Services | Axon Trade',
        description:
          'The partnership will integrate existing and future XTRD trading products with CEX.IO and create a CEX.IO institutional services division',
        keywords: 'institutional crypto traders,institutional investors,cryptocurrency,bitcoin',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-cex.io-partnership.png',
      },
    },
  },
  {
    path: 'how-institutional-investment-will-change-the-crypto-asset-market',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-institutional-investment-will-change-the-crypto-asset-market/how-institutional-investment-will-change-the-crypto-asset-market.component'
      ).then((m) => m.HowInstitutionalInvestmentWillChangeTheCryptoAssetMarketComponent),
    data: {
      sitemap: {
        loc: 'how-institutional-investment-will-change-the-crypto-asset-market',
      },
      meta: {
        loc: 'how-institutional-investment-will-change-the-crypto-asset-market',
        fileName: '/how-institutional-investment-will-change-the-crypto-asset-market',
        title: 'How Institutional Investment Will Change the Crypto Market | Axon Trade',
        description: 'The good and bad of institutional investment in the crypto market.',
        keywords: 'institutional investment,crypto,blockchain,institutional traders,cryptocurrency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-how-to-improve-execution-in-crypto-asset-markets.png',
      },
    },
  },
  {
    path: 'rest-ws-vs-fix-for-crypto-trading',
    loadComponent: () =>
      import('./pages/blog/pages/rest-ws-vs-fix-for-crypto-trading/rest-ws-vs-fix-for-crypto-trading.component').then(
        (m) => m.RestWsVsFixForCryptoTradingComponent
      ),
    data: {
      sitemap: {
        loc: 'rest-ws-vs-fix-for-crypto-trading',
      },
      meta: {
        loc: 'rest-ws-vs-fix-for-crypto-trading',
        fileName: '/rest-ws-vs-fix-for-crypto-trading',
        title: 'REST/Ws vs FIX for crypto trading | Axon Trade',
        description: 'Axon Trade CTO Serg Gulko on differences between REST/Ws and FIX for crypto trading',
        keywords: 'rest api,ws,fix api,crypto trading,industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/REST-WS-vs-FIX-for-Crypto-Trading.png',
      },
    },
  },
  {
    path: 'axon-trade-update-october-31-2018',
    loadComponent: () =>
      import('./pages/blog/pages/axon-trade-update-october-31-2018/axon-trade-update-october-31-2018.component').then(
        (m) => m.AxonTradeUpdateOctober312018Component
      ),
    data: {
      sitemap: {
        loc: 'axon-trade-update-october-31-2018',
      },
      meta: {
        loc: 'axon-trade-update-october-31-2018',
        fileName: '/axon-trade-update-october-31-2018',
        title: 'XTRD Update\u2236 October 31, 2018 | Axon Trade',
        description:
          'Summary\u2236 Product update, New York Trading Show recap, VOA\u0027s Interview with XTRD CTO Serg Gulko, Miami Blockchain Shift Update, Weekly CTO Q&A sessions',
        keywords: 'trading show,product update,crypto trading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-product-update-october-2018.png',
      },
    },
  },
  {
    path: 'blockchain-shift-conference-update',
    loadComponent: () =>
      import('./pages/blog/pages/blockchain-shift-conference-update/blockchain-shift-conference-update.component').then(
        (m) => m.BlockchainShiftConferenceUpdateComponent
      ),
    data: {
      sitemap: {
        loc: 'blockchain-shift-conference-update',
      },
      meta: {
        loc: 'blockchain-shift-conference-update',
        fileName: '/blockchain-shift-conference-update',
        title: 'Blockchain Shift Conference Update | Axon Trade',
        description: 'Axon Trade recently attended the Miami Blockchain Shift conference on October 11–12',
        keywords: 'institutional traders,distributed ledger technology,fintech,events,videos',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-blockchain-shift-conference-update.png',
      },
    },
  },
  {
    path: 'new-york-trading-show-recap',
    loadComponent: () =>
      import('./pages/blog/pages/new-york-trading-show-recap/new-york-trading-show-recap.component').then(
        (m) => m.NewYorkTradingShowRecapComponent
      ),
    data: {
      sitemap: {
        loc: 'new-york-trading-show-recap',
      },
      meta: {
        loc: 'new-york-trading-show-recap',
        fileName: '/new-york-trading-show-recap',
        title: 'New York Trading Show recap | Axon Trade',
        description: 'Axon Trade Attended The New York Trading Show 2018',
        keywords: 'trading show,crypto,crypto trading,cryptocurrency,events',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-new-york-trading-show-recap.png',
      },
    },
  },
  {
    path: 'axon-trade-product-update-october-11-2018',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-product-update-october-11-2018/axon-trade-product-update-october-11-2018.component'
      ).then((m) => m.AxonTradeProductUpdateOctober112018Component),
    data: {
      sitemap: {
        loc: 'axon-trade-product-update-october-11-2018',
      },
      meta: {
        loc: 'axon-trade-product-update-october-11-2018',
        fileName: '/axon-trade-product-update-october-11-2018',
        title: 'Axon Trade product update— October 11, 2018 | Axon Trade',
        description: 'Axon Trade product update — October 11, 2018',
        keywords: 'equity,fx,product update,product updates',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-product-update-october-2018-1.png',
      },
    },
  },
  {
    path: 'top-100-blockchain-and-crypto-influencers-on-twitter-to-follow',
    loadComponent: () =>
      import(
        './pages/blog/pages/top-100-blockchain-and-crypto-influencers-on-twitter-to-follow/top-100-blockchain-and-crypto-influencers-on-twitter-to-follow.component'
      ).then((m) => m.Top100BlockchainAndCryptoInfluencersOnTwitterToFollowComponent),
    data: {
      sitemap: {
        loc: 'top-100-blockchain-and-crypto-influencers-on-twitter-to-follow',
      },
      meta: {
        loc: 'top-100-blockchain-and-crypto-influencers-on-twitter-to-follow',
        fileName: '/top-100-blockchain-and-crypto-influencers-on-twitter-to-follow',
        title: 'Top 100 Blockchain and Crypto Influencers on Twitter | Axon Trade',
        description:
          'The Axon Trade team has compiled a list of Twitter accounts you probably would be interested in following if you work in blockchain & crypto related fields',
        keywords: 'twitter,influencers,crypto,industry',
        og_type: 'article',
        imgUrl:
          '/assets/blog-card-images/axon-trade-top-100-blockchain-and-crypto-influencers-on-twitter-to-follow.png',
      },
    },
  },
  {
    path: 'coming-soon',
    loadComponent: () => import('./pages/coming-soon/coming-soon.component').then((m) => m.ComingSoonComponent),
    data: {
      sitemap: {
        loc: 'coming-soon',
      },
      meta: {
        loc: 'coming-soon',
        fileName: '/coming-soon',
        title: 'Coming soon | Axon Trade',
        description:
          'This page is currently under construction and will be live shortly. We’re committed to providing you with meaningful content and updates, and we’re grateful for your ongoing support.',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
      },
    },
  },
  {
    path: 'booking-confirmation',
    loadComponent: () =>
      import('./pages/booking-confirmation/booking-confirmation.component').then((m) => m.BookingConfirmationComponent),
    data: {
      sitemap: {
        loc: 'booking-confirmation',
      },
      meta: {
        loc: 'booking-confirmation',
        fileName: '/booking-confirmation',
        title: 'Booking confirmation | Axon Trade',
        description: 'You have successfully booked a call on Calendly',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
      },
    },
  },
  {
    path: '404.html',
    loadComponent: () => import('./pages/page404/page404.component').then((m) => m.Page404Component),
    data: {
      sitemap: {
        loc: '404.html',
      },
      meta: {
        loc: '404.html',
        fileName: '/404.html',
        title: '404 | Axon Trade',
        description:
          'We’re sorry, but the page you’re looking for could not be found. It may have been moved, deleted, or never existed in the first place.',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
      },
    },
  },
  {
    path: '**',
    component: Page404Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(filePath)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
